// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-podarunkovi-uroky-js": () => import("./../../../src/pages/podarunkovi-uroky.js" /* webpackChunkName: "component---src-pages-podarunkovi-uroky-js" */),
  "component---src-pages-post-js": () => import("./../../../src/pages/post.js" /* webpackChunkName: "component---src-pages-post-js" */),
  "component---src-pages-pw-forget-js": () => import("./../../../src/pages/pw-forget.js" /* webpackChunkName: "component---src-pages-pw-forget-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-ya-pochynayu-chytaty-js": () => import("./../../../src/pages/ya-pochynayu-chytaty.js" /* webpackChunkName: "component---src-pages-ya-pochynayu-chytaty-js" */),
  "component---src-templates-lesson-js": () => import("./../../../src/templates/lesson.js" /* webpackChunkName: "component---src-templates-lesson-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

